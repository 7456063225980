window.VerifyRuc = function () {
    $('.verify_this_ruc').on('keyup input load', function () {
        var verify_this_ruc = $('.verify_this_ruc').val() || 0;
        if (verify_this_ruc.length > 1) {
            if (ruc_verify(verify_this_ruc) && verify_this_ruc.length == 11) {
                $('.ruc_error').hide();
                $('.ruc_correct').show();
                $('.verify_this_ruc').removeClass('with_error');
                $('.verify_this_ruc_button').removeAttr('disabled');
            } else {
                $('.ruc_error').show();
                $('.ruc_correct').hide();
                $('.verify_this_ruc').addClass('with_error');
                $('.verify_this_ruc_button').prop('disabled', 'disabled');
            }
        } else {
            $('.ruc_error').show();
            $('.ruc_correct').hide();
            $('.verify_this_ruc').addClass('with_error');
            $('.verify_this_ruc_button').prop('disabled', 'disabled');
        }
    });
    $('.verify_this_ruc').trigger("keyup");


    function ruc_verify(valor) {
        valor = trim(valor)
        if (esnumero(valor)) {
            if (valor.length == 8) {
                suma = 0
                for (i = 0; i < valor.length - 1; i++) {
                    digito = valor.charAt(i) - '0';
                    if (i == 0) suma += (digito * 2)
                    else suma += (digito * (valor.length - i))
                }
                resto = suma % 11;
                if (resto == 1) resto = 11;
                if (resto + (valor.charAt(valor.length - 1) - '0') == 11) {
                    return true
                }
            } else if (valor.length == 11) {
                suma = 0
                x = 6
                for (i = 0; i < valor.length - 1; i++) {
                    if (i == 4) x = 8
                    digito = valor.charAt(i) - '0';
                    x--
                    if (i == 0) suma += (digito * x)
                    else suma += (digito * x)
                }
                resto = suma % 11;
                resto = 11 - resto

                if (resto >= 10) resto = resto - 10;
                if (resto == valor.charAt(valor.length - 1) - '0') {
                    return true
                }
            }
        }
        return false
    }

    function trim(cadena) {
        cadena2 = "";
        len = cadena.length;
        for (var i = 0; i <= len; i++) if (cadena.charAt(i) != " ") {
            cadena2 += cadena.charAt(i);
        }
        return cadena2;
    }

    function esnumero(campo) {
        return (!(isNaN(campo)));
    }
}


